import React, { useState, useRef, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import { BsX } from 'react-icons/bs';
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import Draggable from 'react-draggable';
import GoogleMapReact from 'google-map-react';
import MarkerWithInfoBox from './MarkerWithInfoBox';

const MovableSpluDataOverlay = ({ Key, Alias, ID, Code, Voltage, Current, Energy, PowerFactor, Status, Internet, Permission, LastUpdate, UserName, UserMail, UsageEnergy, closeFunction, readOnlyInput, loc_lat, loc_lng, map_loc, device_id, slot_id }) => {
  const [isDragging, setIsDragging] = useState(false);
  const navigate = useNavigate();
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [overlayData, setOverlayData] = useState({});
  const mapRef = useRef(null);
  const defaultProps = {
    center: {
      lat: loc_lat,
      lng: loc_lng,
    },
    zoom: 10,
  };
  
  const handleApiLoaded = (map, maps) => {
    if (map && maps) {
      // Handle map loaded successfully
      //console.log('Google Maps API has loaded.');
    } else {
      console.log('Failled to load google map on browser.');
    }
  };
  
  const handleStart = () => {
    setIsDragging(true);
  };

  const handleStop = () => {
    setIsDragging(false);
  };

  const handleDrag = (e, ui) => {
    const { x, y } = position;
    setPosition({ x: x + ui.deltaX, y: y + ui.deltaY });
  };
  
  const handleMapDrag = (e) => {
    // Prevent propagation of drag events to the parent div
    e.stopPropagation();
  };
  
  const movableOverlayStyle = {
    position: 'fixed',
    top: '35%',
    left: '35%',
    transform: isDragging ? 'translate(-50%, -50%)' : 'translate(-50%, -50%)',
    backgroundColor: 'white',
    border: '1px solid #ccc',
    width: '600px',
    padding: '10px',
    zIndex: 1000,
    cursor: 'move',
    maxHeight: '450px',
    overflowY: 'auto',
  };
  
  const mediaQueryStyle = {
    '@media (min-width: 500px)': {
      maxHeight: '350px',
    },
  };
  
  const overlayHeaderStyle = {
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: '10px',
  };
  
  const closeButtonStyle = {
    padding: '0',
    margin: '0',
  };
  
  const overlayContentStyle = {
    padding: '10px',
  };
  
  const labelStyle = {
    display: 'block',
  };
  
  const inputStyle = {
    width: '100%',
    marginBottom: '10px',
  };
  
  const buttonStyle = {
    backgroundColor: '#007bff',
    color: 'white',
    border: 'none',
    padding: '5px 10px',
    cursor: 'pointer',
  };

  const fetchData = async () => {
    try {
      const token = localStorage.getItem('user-token');
      if (!token) {
        console.error('Token not found in localStorage.');
        localStorage.clear();
        navigate('/auth/login');
        return;
      }
      
      const response = await axios.post('https://spluv2.listrikkita.net/api/detail-spludata', { id: device_id, slot: slot_id }, {
        headers: {
          Authorization: `${token}`,
        },
      });
      
      if (response.data.code === 200 && response.data.status) {
        const resultData = response.data.data;  
        const newOverlayData = { 
          Alias: resultData.device_name,
          ID: resultData.splu_sn.substring(resultData.splu_sn.length - 3).substring(0, 2),
          Code: resultData.splu_sn,
          Voltage: resultData.voltage,
          Current: resultData.current,
          Energy: resultData.energy,
          PowerFactor: resultData.power_factor,
          Status: resultData.is_used,
          Internet: resultData.is_online,
          Permission: resultData.is_enabled,
          LastUpdate: resultData.timestamp,
          UserName: resultData.user_name,
          UserMail: resultData.user_email,
          UsageEnergy: resultData.usage_energy,
          loc_lat: parseFloat(resultData.latitude),
          loc_lng: parseFloat(resultData.longtitude),
          map_loc: resultData.device_name + "<br>Slot ID: " + resultData.splu_sn.substring(resultData.splu_sn.length - 3).substring(0, 2) + "<br>" + resultData.location
        };
        
        setOverlayData(newOverlayData);

      }
    } catch (error) {
      console.error('Error:', error);
    }
  };
  
  useEffect(() => {
    setOverlayData({
      Alias: Alias,
      ID: ID,
      Code: Code,
      Voltage: Voltage,
      Current: Current,
      Energy: Energy,
      PowerFactor: PowerFactor,
      Status: Status,
      Internet: Internet,
      Permission: Permission,
      LastUpdate: LastUpdate,
      UserName: UserName,
      UserMail: UserMail,
      UsageEnergy: UsageEnergy,
      loc_lat: loc_lat,
      loc_lng: loc_lng,
      map_loc: map_loc
    });
    const intervalId = setInterval(() => {
      fetchData(); // Panggil setiap 8 atau 10 detik
    }, 10000); // Ganti dengan 10000 jika ingin setiap 10 detik

    return () => {
      clearInterval(intervalId); // Hentikan interval saat komponen di-unmount
    };
  }, []);
  
  return (
    <Draggable
      onStart={handleStart}
      onStop={handleStop}
      onDrag={handleDrag}
      axis="both"
      defaultPosition={{ x: 0, y: 0 }}
      position={null}
      scale={1}
      zIndex={999}
    >
      <div style={movableOverlayStyle}>
        <div style={overlayHeaderStyle}>
          <Button variant="link" className="close-button" onClick={closeFunction}>
            <BsX color="red" size={24} />
          </Button>
        </div>
        <div style={overlayContentStyle}>
          <label htmlFor="alias">Alias:</label>
          <input id="alias" type={readOnlyInput ? 'text' : 'input'} value={overlayData.Alias} readOnly={readOnlyInput} />
          <label htmlFor="id">SPLU ID:</label>
          <input id="id" type={readOnlyInput ? 'text' : 'input'} value={overlayData.ID} readOnly={readOnlyInput} />
          <label htmlFor="code">SPLU Code:</label>
          <input id="code" type={readOnlyInput ? 'text' : 'input'} value={overlayData.Code} readOnly={readOnlyInput} />
          <label htmlFor="voltage">Voltage:</label>
          <input id="voltage" type={'text'} value={overlayData.Voltage} readOnly={true} />
          <label htmlFor="current">Current:</label>
          <input id="current" type={'text'} value={overlayData.Current} readOnly={true} />
          <label htmlFor="energy">Energy:</label>
          <input id="energy" type={'text'} value={overlayData.Energy} readOnly={true} />
          <label htmlFor="pf">Power Factor:</label>
          <input id="pf" type={'text'} value={overlayData.PowerFactor} readOnly={true} />
          <label htmlFor="status">Status:</label>
          <input id="status" type={'text'} value={overlayData.Status == 1 ? 'On Transaction' : 'Idle'} readOnly={true} />
          <label htmlFor="internet">Internet:</label>
          <input id="internet" type={'text'} value={overlayData.Internet == 1 ? 'Online' : 'Offline'} readOnly={true} />
          <label htmlFor="Permission">Permission:</label>
          <input id="Permission" type={'text'} value={overlayData.Permission == 1 ? 'Enable' : 'Disable'} readOnly={true} />
          <label htmlFor="lastupdate">Last Update:</label>
          <input id="lastupdate" type={'text'} value={overlayData.LastUpdate} readOnly={true} />
          <label htmlFor="username" {...(overlayData.Status === 0 ? { hidden: true } : {})}>Username:</label>
          <input id="username" type={'text'} {...(overlayData.Status === 0 ? { hidden: true } : {})} value={overlayData.UserName} readOnly={true} />
          <label htmlFor="usermail" {...(overlayData.Status === 0 ? { hidden: true } : {})}>Usermail:</label>
          <input id="usermail" type={'text'} {...(overlayData.Status === 0 ? { hidden: true } : {})} value={overlayData.UserMail} readOnly={true} />
          <label htmlFor="usageenergy" {...(overlayData.Status === 0 ? { hidden: true } : {})}>Usage Energy:</label>
          <input id="usageenergy" type={'text'} {...(overlayData.Status === 0 ? { hidden: true } : {})} value={overlayData.UsageEnergy} readOnly={true} />
        </div>
        <div style={{ height: '300px', width: '100%', marginLeft: '3px', marginRight: '3px', marginBottom: '3px', marginTop: '10px' }}>
            <GoogleMapReact
              bootstrapURLKeys={{ key: "AIzaSyDcdYFakFrI0ZqEnz7oOJmy6CHXZ9hXIcg" }}
              onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
              defaultCenter={defaultProps.center}
              defaultZoom={defaultProps.zoom}>
              <MarkerWithInfoBox
                lat={overlayData.loc_lat}
                lng={overlayData.loc_lng}
                text={overlayData.map_loc}
              />
            </GoogleMapReact>
        </div>
      </div>
    </Draggable>
  );
};

export default MovableSpluDataOverlay;
