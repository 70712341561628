import axios from "axios";
import React, { useState } from "react";
import { Button, Col, Container, Form, FormGroup, FormLabel } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import styled from 'styled-components';
import { APP_NAME, IMG_WELCOME, LOGPAG_TITLE } from '../config';
import eyeIcon from 'bootstrap-icons/icons/eye.svg';
import eyeSlashIcon from 'bootstrap-icons/icons/eye-slash.svg';
import $ from 'jquery';

const CenteredDivWrapper = styled.div`
  height: 60vh; /* 75% of viewport height */
  max-width: 500px;
  width: 70%; /* Default width, will change if browser width is below 700px */
  margin: 0 auto; /* Center horizontally */
  background-color: #ffffff; /* Add your desired background color */
  border-radius: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* Add any other styles you want for your centered div */
`;

const Login = () => {
    const loginAPI = 'https://spluv2.listrikkita.net/api/at-login';
    const navigate = useNavigate();
    const submitLoginForm = (event) => {
        event.preventDefault();
        //const formElement = document.querySelector('#loginForm');
        //var formData = new FormData(formElement);
        var formData = new FormData();
        formData.append('client', $('#client').val());
        formData.append('password', passwordInput);
        formData.append('source_url', window.location.origin);
        const formDataJSON = Object.fromEntries(formData);
        const btnPointer = document.querySelector('#login-btn');
        btnPointer.innerHTML = 'Please wait..';
        btnPointer.setAttribute('disabled', true);
        axios.post(loginAPI, formDataJSON).then((response) => {
            btnPointer.innerHTML = 'Login';
            btnPointer.removeAttribute('disabled');
            const data = response.data;
            const token = data.token;
            if (data.status === false) {
              if (data.code === 404 || data.code === 403){
                alert('Unable to login. Please check your email and password.');
              }
              else{
                alert('Unable to login. Please try after some time.');
              }
              return;
            }
            localStorage.clear();
            localStorage.setItem('user-token', token);
            setTimeout(() => {
                navigate('/dashboard');
            }, 500);
        }).catch((error) => {
            btnPointer.innerHTML = 'Login';
            btnPointer.removeAttribute('disabled');
            alert("Unable to serve your request please try again.");
        });
    };
    
    const [passwordType, setPasswordType] = useState("password");
    const [passwordInput, setPasswordInput] = useState("");
    const handlePasswordChange =(evnt)=>{
        setPasswordInput(evnt.target.value);
    }
    const togglePassword =()=>{    
      if(passwordType==="password")
      {
       setPasswordType("text")
       return;
      }
      setPasswordType("password")
    }
    
    return (
        <React.Fragment>
            <Container className="my-5">
                <h2 className="fw-normal mb-2 w-100 text-center bd-highlight" ><strong>{LOGPAG_TITLE}</strong></h2>
                <h3 className="fw-normal mb-5 w-100 text-center">{APP_NAME}</h3>
                <CenteredDivWrapper>
                    <Col md={{span: 8}}>
                        <img className="mx-auto d-block" alt={'app-login-logo'} src={IMG_WELCOME} width="200px" height="200px" style={{ display: 'block', margin: '0 auto' }}/>
                        <Form id="loginForm" onSubmit={submitLoginForm}>
                            <FormGroup className="mb-3">
                                <FormLabel htmlFor={'login-username'}>Email / Username</FormLabel>
                                <input type={'text'} className="form-control" id={'client'} name={'client'} placeholder="email address or username" required />
                            </FormGroup>
                            <FormGroup className="mb-3">
                                <FormLabel htmlFor={'login-password'}>Password</FormLabel>
                                <div className="input-group">
                                    <input
                                      type={passwordType}
                                      onChange={handlePasswordChange}
                                      value={passwordInput}
                                      name={'password'}
                                      id={'Password'}
                                      className="form-control"
                                      placeholder="Password"
                                      style={{
                                        appearance: 'none', // Remove browser-specific styling
                                        WebkitAppearance: 'none', // For WebKit-based browsers (e.g., Chrome, Safari)
                                      }}
                                    />
                                    <div className="input-group-append">
                                      <button
                                        type="button"
                                        className="btn btn-outline-primary"
                                        onClick={togglePassword}>
                                          <img
                                            src={passwordType === "password" ? eyeSlashIcon : eyeIcon}
                                            alt={passwordType === "password" ? "Hide Password" : "Show Password"}
                                          />
                                      </button>
                                    </div>
                                </div>
                            </FormGroup>
                            <Button type="submit" className="btn-success mt-2 w-100 text-center" id="login-btn">Login</Button>
                        </Form>
                    </Col>
                </CenteredDivWrapper>
            </Container>
        </React.Fragment>
    );
}
export default Login;