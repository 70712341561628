import React from 'react';
import { PDFViewer, Document, Page, Text, View } from '@react-pdf/renderer';

const PDFExport = ({ tableData }) => {
  return (
    <PDFViewer>
      <Document>
        <Page size="A4">
          <View>
            <Text>Table Exported to PDF</Text>
            {tableData}
          </View>
        </Page>
      </Document>
    </PDFViewer>
  );
};

export default PDFExport;
