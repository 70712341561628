import React from 'react';

const MarkerWithInfoBox = ({ text }) => (
  <div style={{ position: 'relative', width: '280px' }}>
    <div
      style={{
        backgroundColor: 'white',
        border: '1px solid #ccc',
        padding: '10px',
        borderRadius: '5px',
        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)',
      }}
      dangerouslySetInnerHTML={{ __html: text }}
    ></div>
    <div
      style={{
        width: 0,
        height: 0,
        borderLeft: '10px solid transparent',
        borderRight: '10px solid transparent',
        borderTop: '10px solid white',
        position: 'absolute',
        top: '50%',
        marginTop: '-10px',
        left: '-10px',
      }}
    ></div>
  </div>
);

export default MarkerWithInfoBox;
